<template>
    <Submenu
        :links="[
            { header: 'nouns.neuterNouns.header', icon: 'deer', name: 'nouns-neutratywy' },
            { header: 'nouns.dukajNouns.header', icon: 'ghost', name: 'nouns-dukatywy' },
            { header: 'nouns.personNouns.header', icon: 'user-friends', name: 'nouns-osobatywy' },
            { header: 'nouns.xNouns.header', icon: 'comment-times', name: 'nouns-iksatywy' },
        ]"
    />
</template>
